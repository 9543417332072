import React from 'react';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

export default class ModalExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  show() {
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <div>
        <button className="department cta " id="moneybutton" style={{ background: "transparent" }} onClick={this.show.bind(this)}> ${this.props.product.price}</button>

        <Rodal visible={this.state.visible} onClose={this.hide.bind(this)}>
          <form action="https://node.ihaveavisionfilms.com/paypal/pay" method="POST">
            <div >
              <div className="modalheader"></div>
              <div className="modalbody">



                <label style={{ color: "black", padding: "10px" }}>Please Enter your email address</label>
                <input type="email" name="email" />

                <input type="hidden" name="beatName" value={this.props.product.name} />
                <input type="hidden" name="price" value={this.props.product.price} />
                <input type="hidden" name="tag" value={this.props.product.tag} />




              </div>
              <div className="modalfooter" style={{ paddingTop: "10px" }}>
                <button type="submit" style={{ background: "#0080c2", color: "white", padding: "0.5em", borderRadius: "10px" }}>Pay with Paypal</button>

              </div>
            </div>
          </form>
        </Rodal>
      </div>
    );
  }
}