import React, { useState, useEffect } from 'react'

import StripeCheckout from "react-stripe-checkout"

import axios from "axios";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import ModalExample from "./modal";
import { prettyDOM } from '@testing-library/react';
import { parse } from 'qs';

var qs = require('qs');


toast.configure();



function Item(props) {

  const [product] = React.useState({
    name: props.name,
    genre: props.genre,
    price: props.price,
    tag: props.tag,
    audio: props.url
  });















  let button = "";
  if (product.tag == "FreeDownload") {

    button =
      <a href={"https://strapi.ihaveavisionfilms.com" + product.audio} id="freebutton" className="departement cta" style={{ fontSize: "10px" }}>
        free download
 </a>;

  }


  useEffect(() => {

    if (product.tag == "FreeDownload") {
      document.getElementById('moneybutton').style.display = "none";
    }
    

  }, []);














  let catClass = ""

  if (product.tag == "FreeDownload") {
    catClass = "btn-free"
  } else if (product.tag == "StandardLease") {
    catClass = "btn-standard"
  } else if (product.tag == "ExclusiveLease") {
    catClass = "btn-exclusive"
  } else if (product.tag === "AudioForFilm") {
    catClass = "btn-film"
  }



  return (

    <React.Fragment>

      <div className="postes-row">
        <div className="titre">
          <h3>{product.name}
            <div style={{ display: "flex", justifyContent: "flex-start" }}>

              <div><h6 style={{ fontSize: "12px" }} className="btn-genre"> Genre: {product.genre}</h6></div>
              <div className={catClass} style={{ display: 'flex', justifyItems: 'flex-start' }}>
                {product.tag}
              </div>
            </div>
          </h3>
        </div>


        <div className="localisation">
          <audio controls>
            <source src={"https://strapi.ihaveavisionfilms.com" + product.audio} />
            Your browser does not support the audio element.
          </audio>
        </div>



        <div className="type">
          <h3 />
        </div>


        <ModalExample product={product} />




        {button}




















        <div className="mobile-infos">
          <div className="titre"><h3>{product.name}
            <a className={catClass}>{product.tag}</a>

          </h3></div>
          <div className="localisation">
            <audio controls style={{ width: '250px' }}>
              <source src={"https://strapi.ihaveavisionfilms.com" + product.audio} />
              Your browser does not support the audio element.
            </audio>
          </div>
          <div className="type" />
          {/* stripe button here */}

        </div>
      </div>
    </React.Fragment>

  )
}

export default Item
